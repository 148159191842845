/* eslint-disable prefer-template */
// feb2023
/* eslint-disable @typescript-eslint/quotes */

import { getExploreName } from '../utils/platform';

export const devConfig = {
  // sam credentials
  // multi vcc - sam
  // sdkKey: 'JiDx0SWKMD8ppLWt3YP1xE3Cp88NmUW0xYqV',
  // sdkSecret: 'lN8k69PB6LCUU6KZ43vA1cQWhf27vCjioixH',
  // cti main zoom - must be same at signature token API //
  // sdkKey: 'DsW3AkRIuau9ebjun1qPY0JqGDpTXb7v39nS',
  // sdkSecret: 'pL43KUR485FhtxqDcgePRloPnZCzDbVdmzJq',
  // lememorial
  // Demo credentials //
  // sdkKey: 'JvUbJxqrwl6N8FIxbzKjcl2b4y3M5rEUhfZv',
  // sdkSecret: 'ZMx9aeIYkMycPLNq8eLhnMlD1rrgDhHJAoVO',
  // New Heritage - SAS Keys ...
  sdkKey: 'Sz7gYuB2ada7Vh2G1Fj95p0FdOdxoPpLyXKH',
  sdkSecret: 'NQmSaQjMY6q6oE4qDTIEvRZnTvhkyNq12web',
  // feb2023
  webEndpoint: 'zoom.us',
  // sas zoom
  // sdkKey: '2yeu97YNimXdk5jq7DffFnIFkPSFLuY3aQVX',
  // sdkSecret: 'MyyjkMD6GngcuOKgVeHnpALSjCqp1iNROJHU',
  // iml zoom
  // sdkKey: '5miZbU0t7O9DGm1jCN5PwsNnqV07CGgo7yiv',
  // sdkSecret: 'xU2PNFbgDRRCLMv2VTg3aIQXVmEwngODgydK',
  topic: 'jack',
  // name: `${getExploreName()}-${Math.floor(Math.random() * 1000)}`,
  name: `Admin-${Math.floor(Math.random() * 1000)}`,
  password: 'jack123',
  signature: '',
  sessionKey: 'jack',
  userIdentity: 'jack',
  // tests
  // parameter used in join session ...
  sessionIdleTimeoutMins: 240, // The amount of time in minutes after which an idle session will end.
  // The amount of time in minutes after which an idle session will end. If the value is 0, the session will stay alive indefinitely. 
  // sessionIdleTimeoutMins: 0,
  // 480 - Session can be idle for 8 hrs... Useless for session to be idle though ... its better appointments kick in //
  // its 60 mins to enable dev tests
  // For Production Increase to max === 
  roleType: 1, // role=1, host, role=0 is attendee, only role=1 can start session when session not start,
  // test params //
  test_topic: 'jacktest' + String(new Date().getTime()),
  test_name: 'jacktest',
  test_password: 'jack23tes', // max 10 characters ...
  test_signature: '',
  // test_lifedateId: 'test_DrSam',
  // test_sessionId: 'test_samueltest123',
  test_sessionIdleTimeoutMins: 1,
  // db params //
  // demo clinic
  // clinicId: 9,
  // heritage clinic
  // 3 is for heritage //
  // 6 is for IML //
  // 9 is for Demo clinic //
  clinicId: 3,
  mainUrl: 'https://core.lifehealth.app/v1/',
  // dialogLink: 'https://demo-cc.lifehealth.app/doctor-visit/',
  dialogLink: 'https://healthadmin.lifehealth.app/consultation/view?id=',
  // dialogLink: 'https://testcc.ctiafrica.io/doctor-visit/',
  walletLink: 'https://vitals.lifehealth.app/v1/',
  presessionId: 'CTC',
  prepasscode: 'ctc',
  version: '3.0.2',
  welcome_version: 'v3.0.2',
  // portalname: 'Demo CTC Command Center v2.0.1',
  // generalname: 'Zoom Health Demo',
  portalname: 'Heritage Medical Command Center v3.0.2 (pdn_)',
  generalname: 'Zoom Health',
  logourl: '',
  slogan: 'Health Care for the Future',
  // CTI AFRICA 2020
  // Change Port ...
  copyright: 'CTI AFRICA LLC 2023',
  // officialurl: 'demo.4ws.live',
  // port: '3013',
  officialurl: 'hms_zoom.4ws.live',
  port: '3080',
  // feb2023
  // role = 1 to join as host, 0 to join as attendee. The first user must join as host to start the session
  role: 1,
  enforceGalleryView: true,
  cloud_recording_option: "0",
  cloud_recording_election: "",
  
};
